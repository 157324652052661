import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useAgents } from "../../Hooks/useAgents";
import SelectComponent from "../../Components/Select";
import InputComponent from "../../Components/Input";
import ButtonCustom from "../../Components/Button";

import { FaCopy, FaCheck } from "react-icons/fa6";
import { AiOutlineSearch } from "react-icons/ai";
import TableCustom from "../../Components/Table";
import { IoIosMore } from "react-icons/io";
import moment from "moment";
import "./style.css";
import ProtocolsDetails from "./RequirementsDetailsModal";
import { IoAdd } from "react-icons/io5";
import AddModal from "./AddRequirementsModal";

const RequirementsList = () => {
  const {
    getAgentsList,
    agentsList,
    isAgentsLoading,
    protocolsList,
    getRequirementsList,
    requirementsList,
  } = useAgents();
  const [uuid, setUuid] = useState("30d75f0c-2530-49a3-9b06-66ea8eb3f147"); //belluno
  const [limit, setLimit] = useState("20");
  const [order, setOrder] = useState("desc");
  const [offset, setOffset] = useState("0");
  const [inputErr, setInputErr] = useState(false);
  const [selectedProtocol, setSelectedProtocol] = useState<any>();
  const [selectedRequirement, setSelectedRequirement] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [copied, setCopied] = useState({
    id: "",
    copy: false,
  });

  useEffect(() => {
    getAgentsList();
  }, []);

  const options = useMemo(() => {
    return agentsList.map(({ uuid, name }) => {
      return { id: uuid, label: name };
    });
  }, [agentsList]);

  const totalItems = (id: string) => {
    let aux = protocolsList?.find((e) => e.id === id)?.data;
    return aux?.query ? 0 : aux?.length;
  };

  const handleSearch = () => {
    if (Number(limit) <= 0 || Number(offset) < 0 || !uuid || !order) {
      setInputErr(true);
    } else {
      let body = {
        agent: uuid,
        limit: Number(limit),
        offset: offset ? Number(offset) : 0,
        order: order ? order : "desc",
      };
      getRequirementsList(body);
    }
  };

  useEffect(() => {
    if (uuid !== "") handleSearch();
  }, []);

  useEffect(() => {
    // console.log("protocolsList---->", protocolsList);
    // console.log("requirementsList---->", requirementsList);
  }, [protocolsList, requirementsList]);

  const handleSelectProtocol = (protocol: string, query: any) => {
    const findProtocol = protocolsList.find(({ id }) => protocol === id);
    setSelectedRequirement(query);
    setSelectedProtocol(findProtocol);
    setShowDetailsModal(true);
  };

  const msgTableColumns = [
    {
      dataField: "id",
      text: "Requerimento",
      sort: false,
    },

    {
      dataField: "created",
      text: "Recebido",
      sort: true,
    },
    {
      dataField: "call",
      text: "Método",
      sort: true,
    },
    {
      dataField: "data",
      text: "Items",
      sort: true,
    },
    {
      dataField: "acoes",
      text: "",
    },
  ];

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Mais detalhes
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Row className="mt-sm-3 mt-lg-0 d-flex align-itens-center">
        <Col lg={3}>
          <SelectComponent
            placeholder="Selecione um agente..."
            options={options}
            setSelectedItem={setUuid}
            selectedItem={uuid}
            disabled={isAgentsLoading || options.length <= 0}
            label="Agentes"
            isInvalid={inputErr && !uuid}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Limite"
            placeholder="Limite de mensagens..."
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            inputId="limit"
            min={1}
            isInvalid={inputErr && Number(limit) <= 0}
            errorMsg="Deve ser maior que 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Offset"
            placeholder="Offset..."
            value={offset}
            onChange={(e) => {
              setOffset(e.target.value);
            }}
            inputId="offset"
            min={0}
            isInvalid={inputErr && Number(limit) < 0}
            errorMsg="Deve ser maior que ou igual a 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <SelectComponent
            placeholder="Selecione uma ordem..."
            options={[
              { id: "desc", label: "Descendente" },
              { id: "asc", label: "Ascendente" },
            ]}
            setSelectedItem={setOrder}
            selectedItem={order}
            disabled={isAgentsLoading}
            label="Ordem"
            isInvalid={inputErr && !order}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col sm={1} className="d-flex align-items-end">
          <ButtonCustom
            className="p-2 d-flex align-items-center justify-content-center search-btn"
            onClick={handleSearch}
            disabled={isAgentsLoading}
          >
            {isAgentsLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            ) : (
              <AiOutlineSearch size={22} />
            )}
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="m-0 mt-sm-3 px-1 d-flex flex-column gap-2 gap-sm-0 flex-sm-row align-items-sm-center justify-content-sm-between">
        <Col className="p-0 m-0">
          <h5 className="m-0">Lista de Requerimentos</h5>
        </Col>

        <Col lg={3} className="p-0 m-0 d-flex justify-content-end">
          <ButtonCustom
            onClick={() => {
              setShowAddModal(true);
            }}
            className="d-flex align-items-center gap-2 py-1"
            disabled={isAgentsLoading}
          >
            <IoAdd size={20} />
            <span>Criar Requerimento</span>
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="m-0 px-1 table-messages">
        {isAgentsLoading ? (
          <Col className="mt-5 w-100 d-flex align-items-center justify-content-center gap-2">
            <Spinner animation="border" />
            <span className="fs-5">Carregando...</span>{" "}
          </Col>
        ) : (
          <TableCustom
            keyField="id"
            columns={msgTableColumns}
            noDataIndication="Nenhuma mensagem encontrada..."
            data={
              requirementsList?.length > 0
                ? requirementsList.map((e) => {
                    return {
                      id: e.id,

                      created: moment
                        .utc(e.created)
                        .format("DD/MM/YY HH:mm:ss"),
                      call: e.call,
                      data: totalItems(e.id),
                      acoes: (
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <button
                            className="details-button"
                            onClick={() => {
                              handleSelectProtocol(e.id, e.data);
                            }}
                          >
                            <IoIosMore size={30} />
                          </button>
                        </OverlayTrigger>
                      ),
                    };
                  })
                : []
            }
          />
        )}
      </Row>

      {showDetailsModal && (
        <ProtocolsDetails
          show={showDetailsModal}
          setShow={setShowDetailsModal}
          protocol={selectedProtocol}
          requirementQuery={selectedRequirement}
        />
      )}

      {showAddModal && (
        <AddModal
          show={showAddModal}
          setShow={setShowAddModal}
          setReqLimit={setLimit}
          setReqOffset={setOffset}
          setReqOrder={setOrder}
          setReqUuid={setUuid}
          agentsList={agentsList}
        />
      )}
    </React.Fragment>
  );
};

export default RequirementsList;
