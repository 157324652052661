import { ReactNode } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Col } from "react-bootstrap";
import { TableContainer } from "./style";

type Column = {
  dataField: string;
  text: any;
  sort?: boolean;
  sortFunc?: any;
};

type TableProps = {
  columns: Column[];
  data: any[];
  keyField: string;
  noDataIndication: string;
};

const TableCustom = ({
  columns,
  data,
  keyField,
  noDataIndication,
}: TableProps) => {
  return (
    <TableContainer>
      <BootstrapTable
        bootstrap4
        keyField={keyField}
        data={data}
        columns={columns}
        noDataIndication={noDataIndication}
        bordered={false}
        striped
      />
    </TableContainer>
  );
};

export default TableCustom;
