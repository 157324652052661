import { Dispatch, SetStateAction, useState } from "react";
import { useAgents } from "../../Hooks/useAgents";

//components
import { Col, Modal, Row } from "react-bootstrap";
import InputComponent from "../../Components/Input";
import ButtonCustom from "../../Components/Button";

//icons
import { LuX, LuSave } from "react-icons/lu";
import ModalContainer from "../../Components/Modal";
import { cnpjMask } from "../../Utils/Masks";

interface IModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const CreateAgentModal = ({ show, setShow }: IModalProps) => {
  const { createAgent, isAgentsLoading } = useAgents();
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inputErr, setInputErr] = useState(false);

  const handleSave = () => {
    if (name === "" || cnpj === "") {
      setInputErr(true);
    } else {
      setInputErr(false);
      let body = {
        name,
        cnpj: cnpj.replace(/\D/g, ""),
      };

      console.log(body);
      createAgent(body, handleClose);
    }
  };

  const handleClose = () => {
    if (!isAgentsLoading) {
      setName("");
      setCnpj("");
      setInputErr(false);
      setShow(false);
    }
  };

  return (
    <ModalContainer
      show={show}
      closeFn={handleClose}
      title="Criar Agente"
      hasFooter
      footer={
        <Row className="m-0">
          <Col>
            <ButtonCustom
              onClick={handleSave}
              className="d-flex align-items-center gap-2 py-2"
              disabled={isAgentsLoading}
              isLoading={isAgentsLoading}
            >
              <LuSave />
              <span>Salvar</span>
            </ButtonCustom>
          </Col>

          <Col>
            <ButtonCustom
              className="d-flex align-items-center gap-2 py-2"
              variant="danger"
              onClick={handleClose}
              disabled={isAgentsLoading}
            >
              <LuX />
              <span>Cancelar</span>
            </ButtonCustom>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col className="mb-4">
          <InputComponent
            label="Nome"
            placeholder="Digite o nome do agente..."
            isInvalid={inputErr && name === ""}
            errorMsg="Campo Obrigatório."
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputId="name"
            disabled={isAgentsLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col className="mb-4">
          <InputComponent
            label="CNPJ"
            placeholder="Digite o cnpj do agente..."
            isInvalid={inputErr && cnpj === ""}
            errorMsg="Campo Obrigatório."
            value={cnpj}
            onChange={(e) => setCnpj(cnpjMask(e.target.value))}
            inputId="cnpj"
            disabled={isAgentsLoading}
          />
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default CreateAgentModal;
