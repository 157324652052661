import { ReactNode, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SideMenu from "../Menu";
import { ContentCol, NavCol } from "./style";

type PageTemplateProps = {
  children: ReactNode;
  showMenu?: boolean;
};

const PageTemplate = ({ children, showMenu = true }: PageTemplateProps) => {
  const [hideMenu, setHideMenu] = useState(false);

  const currentUrl = useMemo(() => {
    return window.location.pathname;
  }, []);

  return (
    <Row className="w-100 h-100 m-0 p-0 d-flex flex-column flex-lg-row">
      {showMenu && (
        <NavCol hideMenu={hideMenu}>
          <SideMenu hideMenu={hideMenu} setHideMenu={setHideMenu} />
        </NavCol>
      )}

      <ContentCol hideMenu={hideMenu} url={currentUrl}>
        {children}
      </ContentCol>
    </Row>
  );
};

export default PageTemplate;
