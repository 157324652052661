import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useAgents } from "../../Hooks/useAgents";
import SelectComponent from "../../Components/Select";
import InputComponent from "../../Components/Input";
import ButtonCustom from "../../Components/Button";

import { AiOutlineSearch } from "react-icons/ai";
import TableCustom from "../../Components/Table";
import { IoIosMore } from "react-icons/io";
import moment from "moment";
import "./style.css";
import ProtocolsDetails from "./ProtocolsDetailsModal";
import { toast } from "react-toastify";
import { validate as uuidValidate } from "uuid";

const ProtocolsList = () => {
  const {
    getAgentsList,
    agentsList,
    isAgentsLoading,
    getAgentProtocolsList,
    protocolsList,
    getProtocol,
    foundProtocol,
    isProtocolLoading,
    getRequirementsList,
    requirementsList,
  } = useAgents();
  const [uuid, setUuid] = useState("30d75f0c-2530-49a3-9b06-66ea8eb3f147"); //belluno
  const [limit, setLimit] = useState("20");
  const [order, setOrder] = useState("desc");
  const [offset, setOffset] = useState("0");
  const [inputErr, setInputErr] = useState(false);
  const [selectedProtocol, setSelectedProtocol] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [protocolToSearch, setProtocolToSearch] = useState("");
  const [protocols, setProtocols] = useState<any[]>([]);
  const [selectedQuery, setSelectedQuery] = useState<any>();

  useEffect(() => {
    getAgentsList();
  }, []);

  const options = useMemo(() => {
    return agentsList.map(({ uuid, name }) => {
      return { id: uuid, label: name };
    });
  }, [agentsList]);

  const handleSearch = () => {
    if (Number(limit) <= 0 || Number(offset) < 0 || !uuid || !order) {
      setInputErr(true);
    } else {
      let body = {
        agent: uuid,
        limit: Number(limit),
        offset: offset ? Number(offset) : 0,
        order: order ? order : "desc",
      };
      getAgentProtocolsList(body, setProtocols);
      getRequirementsList(body);
    }
  };

  const handleProtocolSearch = () => {
    if (protocolToSearch !== "") {
      if (uuidValidate(protocolToSearch)) {
        getProtocol(protocolToSearch, setProtocols);
      } else {
        toast.error("O protocolo deve ser um uuid válido.");
      }
    } else {
      handleSearch();
    }
  };

  useEffect(() => {
    if (uuid !== "") handleSearch();
  }, []);

  useEffect(() => {
    // console.log("selectedQuery", selectedQuery);
  }, [selectedQuery]);

  const handleSelectProtocol = (protocol: any) => {
    setSelectedProtocol(protocol);
    setShowDetailsModal(true);
    let aux = requirementsList.find(({ id }) => protocol.id === id).data;
    setSelectedQuery(aux);
  };

  useEffect(() => {
    if (foundProtocol) setUuid(foundProtocol.agent);
  }, [foundProtocol]);

  const msgTableColumns = [
    {
      dataField: "id",
      text: "Protocolo",
      sort: true,
    },

    {
      dataField: "created",
      text: "Recebido",
      sort: true,
    },
    {
      dataField: "call",
      text: "Método",
      sort: true,
    },
    {
      dataField: "data",
      text: "Items",
      sort: true,
    },
    {
      dataField: "acoes",
      text: "",
    },
  ];

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Mais detalhes
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Row className="mt-sm-3 mt-lg-0 d-flex align-itens-center">
        <Col lg={3}>
          <SelectComponent
            placeholder="Selecione um agente..."
            options={options}
            setSelectedItem={setUuid}
            selectedItem={uuid}
            disabled={isAgentsLoading || options.length <= 0}
            label="Agentes"
            isInvalid={inputErr && !uuid}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Limite"
            placeholder="Limite de mensagens..."
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            inputId="limit"
            min={1}
            isInvalid={inputErr && Number(limit) <= 0}
            errorMsg="Deve ser maior que 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Offset"
            placeholder="Offset..."
            value={offset}
            onChange={(e) => {
              setOffset(e.target.value);
            }}
            inputId="offset"
            min={0}
            isInvalid={inputErr && Number(limit) < 0}
            errorMsg="Deve ser maior que ou igual a 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <SelectComponent
            placeholder="Selecione uma ordem..."
            options={[
              { id: "desc", label: "Descendente" },
              { id: "asc", label: "Ascendente" },
            ]}
            setSelectedItem={setOrder}
            selectedItem={order}
            disabled={isAgentsLoading}
            label="Ordem"
            isInvalid={inputErr && !order}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col sm={1} className="d-flex align-items-end justify-content-end">
          <ButtonCustom
            className="p-2 d-flex align-items-center justify-content-center search-btn"
            onClick={handleSearch}
            disabled={isAgentsLoading || isProtocolLoading}
          >
            {isAgentsLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            ) : (
              <AiOutlineSearch size={22} />
            )}
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="mt-sm-3 d-flex flex-column gap-2 gap-sm-0 flex-sm-row align-items-sm-center justify-content-sm-between">
        <Col className="m-0">
          <h5 className="m-0">Lista de Protocolos</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <Col>
            <InputComponent
              inputId="protocol-search"
              placeholder="Procurar por protocolo..."
              value={protocolToSearch}
              onChange={(e) => setProtocolToSearch(e.target.value)}
            />
          </Col>
          <Col sm={2} className="d-flex justify-content-end">
            <ButtonCustom
              className="p-2 d-flex align-items-center justify-content-center search-btn"
              onClick={handleProtocolSearch}
              disabled={isProtocolLoading || isAgentsLoading}
            >
              {isProtocolLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              ) : (
                <AiOutlineSearch size={22} />
              )}
            </ButtonCustom>
          </Col>
        </Col>
      </Row>

      <Row className="m-0 px-1 table-messages">
        {isAgentsLoading || isProtocolLoading ? (
          <Col className="mt-5 w-100 d-flex align-items-center justify-content-center gap-2">
            <Spinner animation="border" />
            <span className="fs-5">Carregando...</span>{" "}
          </Col>
        ) : (
          <TableCustom
            keyField="id"
            columns={msgTableColumns}
            noDataIndication="Nenhuma mensagem encontrada..."
            data={
              protocols?.length > 0
                ? protocols.map((e) => {
                    return {
                      id: e.id,
                      created: e.created
                        ? moment.utc(e.created).format("DD/MM/YY HH:mm:ss")
                        : "",
                      call: e.call,
                      data: e.data?.query ? 0 : e.data?.length,
                      acoes: (
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <button
                            className="details-button"
                            onClick={() => {
                              handleSelectProtocol(e);
                            }}
                          >
                            <IoIosMore size={30} />
                          </button>
                        </OverlayTrigger>
                      ),
                    };
                  })
                : []
            }
          />
        )}
      </Row>

      {showDetailsModal && (
        <ProtocolsDetails
          show={showDetailsModal}
          setShow={setShowDetailsModal}
          protocol={selectedProtocol}
          requirementQuery={selectedQuery}
        />
      )}
    </React.Fragment>
  );
};

export default ProtocolsList;
