// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.table-agents
  > .col
  > div.react-bootstrap-table
  > table
  > thead
  > tr
  > th:first-child {
  width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Agents/style.css"],"names":[],"mappings":"AAAA;;;;;;;EAOE,YAAY;AACd","sourcesContent":[".row.table-agents\n  > .col\n  > div.react-bootstrap-table\n  > table\n  > thead\n  > tr\n  > th:first-child {\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
