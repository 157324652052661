//hooks
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

//components
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import ButtonCustom from "../Button";
import BootclientLogo from "../MainLogo";
import { CustomNavbar, NavContent, NavLink, NavUser } from "./style";
import SweetAlert from "../../Utils/AlertMessages";
import { toast } from "react-toastify";

//icons
import { IoHomeSharp, IoHomeOutline, IoMenu } from "react-icons/io5";
import {
  BsBuilding,
  BsBuildingFill,
  BsFileEarmarkText,
  BsFileEarmarkTextFill,
} from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa6";
import { HiUsers, HiOutlineUsers } from "react-icons/hi";
import { MdMailOutline, MdMail } from "react-icons/md";
import {
  PiUserCircleDuotone,
  PiClipboardText,
  PiClipboardTextFill,
} from "react-icons/pi";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";

interface IMenuProps {
  hideMenu: boolean;
  setHideMenu: Dispatch<SetStateAction<boolean>>;
}

const SideMenu = ({ hideMenu, setHideMenu }: IMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [userName, setUserName] = useState(localStorage.getItem("USER"));
  const [userRole, setUserRole] = useState(localStorage.getItem("ROLE"));

  const logOut = () => {
    SweetAlert.default({
      title: "Deseja sair do sistema?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sim, desconectar!",
      callback: () => {
        localStorage.clear();
        navigate("/");
        toast.success("Desconectado!");
      },
    });
  };

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    setActiveLink(location.pathname);
    window.addEventListener("resize", handleResize);
  }, [location.pathname]);

  return (
    <CustomNavbar expand="lg" variant="dark">
      <Container className="px-0">
        <Navbar.Brand className="m-0">
          {hideMenu ? (
            <BootclientLogo type="small" width="100%" />
          ) : (
            <BootclientLogo type="white" width="45vw" maxWidth="200px" />
          )}
        </Navbar.Brand>

        <Navbar.Toggle>
          <IoMenu />
        </Navbar.Toggle>

        {isMobile && (
          <ButtonCustom
            variant="light"
            onClick={logOut}
            className="d-flex align-items-center gap-1"
          >
            <FaPowerOff size={16} />
          </ButtonCustom>
        )}
      </Container>

      <NavUser hideMenu={hideMenu}>
        {!hideMenu && (
          <div>
            <PiUserCircleDuotone size={22} color="white" />
            <div>
              <p>{userName}</p>
              <span>{userRole === "user" ? "Usuário" : "Administrador"}</span>
            </div>
          </div>
        )}

        {!isMobile && (
          <ButtonCustom onClick={() => setHideMenu(!hideMenu)}>
            {hideMenu ? (
              <RiMenuUnfoldLine size={26} />
            ) : (
              <RiMenuFoldLine size={26} />
            )}
          </ButtonCustom>
        )}
      </NavUser>

      <Navbar.Collapse className="w-100 align-items-lg-start">
        <NavContent>
          <NavLink
            active={activeLink === "/inicio"}
            href="/inicio"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/inicio" ? (
              <IoHomeSharp size={hideMenu ? 20 : 16} />
            ) : (
              <IoHomeOutline size={hideMenu ? 20 : 16} />
            )}
            {!isMobile && !hideMenu && <span>Início</span>}
          </NavLink>

          <NavLink
            active={activeLink === "/agentes"}
            href="/agentes"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/agentes" ? (
              <BsBuildingFill size={hideMenu ? 20 : 16} />
            ) : (
              <BsBuilding size={hideMenu ? 20 : 16} />
            )}
            {!isMobile && !hideMenu && <span>Agentes</span>}
          </NavLink>

          <NavLink
            active={activeLink === "/usuarios"}
            href="/usuarios"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/usuarios" ? (
              <HiUsers size={hideMenu ? 20 : 16} />
            ) : (
              <HiOutlineUsers size={hideMenu ? 20 : 16} />
            )}
            {!isMobile && !hideMenu && <span>Usuários</span>}
          </NavLink>

          <NavLink
            active={activeLink === "/mensagens"}
            href="/mensagens"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/mensagens" ? (
              <MdMail size={hideMenu ? 20 : 18} />
            ) : (
              <MdMailOutline size={hideMenu ? 20 : 18} />
            )}
            {!isMobile && !hideMenu && <span>Mensagens</span>}
          </NavLink>

          <NavLink
            active={activeLink === "/requerimentos"}
            href="/requerimentos"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/requerimentos" ? (
              <PiClipboardTextFill size={hideMenu ? 20 : 18} />
            ) : (
              <PiClipboardText size={hideMenu ? 20 : 18} />
            )}
            {!isMobile && !hideMenu && <span>Requerimentos</span>}
          </NavLink>

          <NavLink
            active={activeLink === "/protocolos"}
            href="/protocolos"
            className={hideMenu ? "d-flex justify-content-center" : ""}
          >
            {activeLink === "/protocolos" ? (
              <BsFileEarmarkTextFill size={hideMenu ? 20 : 16} />
            ) : (
              <BsFileEarmarkText size={hideMenu ? 20 : 16} />
            )}
            {!isMobile && !hideMenu && <span>Protocolos</span>}
          </NavLink>
        </NavContent>
      </Navbar.Collapse>

      {!isMobile && (
        <ButtonCustom
          variant="light"
          onClick={logOut}
          className={`d-flex align-items-center gap-1 ${hideMenu ? "p-2" : ""}`}
        >
          <FaPowerOff size={16} />
          {!hideMenu && <span>Desconectar</span>}
        </ButtonCustom>
      )}
    </CustomNavbar>
  );
};

export default SideMenu;
