// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-label {
  color: var(--color-gray);
  font-size: 18px;
  margin-bottom: 0.3rem;
}

.form-control:focus {
  box-shadow: var(--input-shadow);
  border-color: var(--input-border-focus);
}

.invalid-feedback {
  position: absolute;
  margin-top: -0.15rem;
  margin-left: 0.25rem;
  width: -moz-fit-content;
  width: fit-content;
}

.form-control:focus-visible {
  outline: none;
}

input[id="password"].form-control {
  border: none;
}

input[id="password"].form-control:focus {
  box-shadow: none;
  border-color: none;
}

input[id="password"].form-control:disabled {
  background-color: transparent;
}

.input-group {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group[aria-disabled="true"] {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.input-group:focus-within {
  box-shadow: var(--input-shadow);
  border-color: var(--input-border-focus);
}

.btn.btn-primary.password-btn {
  background-color: transparent;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Input/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;EAC/B,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2DAA2D;EAC3D,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;EACxC,UAAU;AACZ;;AAEA;EACE,+BAA+B;EAC/B,uCAAuC;AACzC;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".form-label {\n  color: var(--color-gray);\n  font-size: 18px;\n  margin-bottom: 0.3rem;\n}\n\n.form-control:focus {\n  box-shadow: var(--input-shadow);\n  border-color: var(--input-border-focus);\n}\n\n.invalid-feedback {\n  position: absolute;\n  margin-top: -0.15rem;\n  margin-left: 0.25rem;\n  width: fit-content;\n}\n\n.form-control:focus-visible {\n  outline: none;\n}\n\ninput[id=\"password\"].form-control {\n  border: none;\n}\n\ninput[id=\"password\"].form-control:focus {\n  box-shadow: none;\n  border-color: none;\n}\n\ninput[id=\"password\"].form-control:disabled {\n  background-color: transparent;\n}\n\n.input-group {\n  border: var(--bs-border-width) solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n}\n\n.input-group[aria-disabled=\"true\"] {\n  background-color: var(--bs-secondary-bg);\n  opacity: 1;\n}\n\n.input-group:focus-within {\n  box-shadow: var(--input-shadow);\n  border-color: var(--input-border-focus);\n}\n\n.btn.btn-primary.password-btn {\n  background-color: transparent;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
