import styled from "styled-components";

type ImgProps = {
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
};

export const LogoImg = styled.img<ImgProps>`
  width: ${({ width }) => (width ? width : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
`;
