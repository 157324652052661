import { Col } from "react-bootstrap";
import styled from "styled-components";

export const TableContainer = styled(Col)`
  max-height: 60vh;
  overflow-y: auto;
  margin: 15px 0px;
  padding: 0px 2px 0px 0px;

  > div.react-bootstrap-table > table {
    margin: 0px;
    font-size: 14px;
    position: relative;

    > thead > tr > th {
      text-transform: capitalize;
      position: sticky;
      top: 0;
    }

    > tbody > tr > td {
      border-color: var(--color-gray-0);
      text-transform: capitalize;
    }
  }
`;
