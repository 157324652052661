import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalContainer from "../../Components/Modal";
import { Accordion, Alert, Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { AccordionHeader, Item, ItemHeader } from "./modalStyle";
import { IoWarningOutline } from "react-icons/io5";
import InputComponent from "../../Components/Input";
import { AiOutlineSearch } from "react-icons/ai";

interface IMsgDetailsProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  message: {
    created: string;
    payload: {
      id: string;
      call: string;
      error: string;
      data: any[];
    };
  };
}

const MessagesDetails = ({ show, setShow, message }: IMsgDetailsProps) => {
  const [filterInput, setFilterInput] = useState("");
  const [dataList, setDataList] = useState<any[]>(message.payload.data);
  const handleClose = () => {
    setShow(false);
  };

  const handleFilterData = (value: string) => {
    if (value === "") {
      setDataList(message.payload.data);
    } else {
      const filteredData = message.payload.data.filter((obj) => {
        // let keys = Object.keys(obj).join(" ").toLowerCase();
        let values = Object.values(obj).join(" ").toLowerCase();
        return (
          // keys.includes(value.toLowerCase()) ||
          values.includes(value.toLowerCase())
        );
      });

      setDataList(filteredData);
    }
  };

  const decodeStr = (field: string, value: string) => {
    if (
      field === "liberado" ||
      field === "bloqueadoadmveic" ||
      field === "cancelado" ||
      field === "liberadomot" ||
      field === "liberadoprop" ||
      field === "liberadoveic" ||
      field === "ufcidadedestino" ||
      field === "ufcidadeorigem" ||
      field === "bloqueadoadm" ||
      field === "bloqueadoadmprop" ||
      field === "bloqueadoadmmot"
    ) {
      return atob(value);
    } else {
      return value;
    }
  };

  useEffect(() => {
    // console.log("--", dataList);
  }, [dataList]);

  useEffect(() => {
    handleFilterData(filterInput);
  }, [filterInput]);

  return (
    <ModalContainer
      show={show}
      closeFn={handleClose}
      hasFooter={false}
      size="lg"
      title={`${message.payload.call} - ${moment(message.created).format(
        "DD/MM/YY HH:mm:ss"
      )} - ${message.payload.id}`}
    >
      {message.payload.error !== "" && (
        <Row className="mx-0">
          <Col className="p-0">
            <Alert variant="danger" className="d-flex gap-2">
              <div>
                <IoWarningOutline size={28} />
              </div>
              <span>{message.payload.error}</span>
            </Alert>
          </Col>
        </Row>
      )}

      <Row className="mx-0 mb-3">
        <Col md={4} className="p-0 d-flex align-items-center gap-2">
          <InputComponent
            placeholder="Procurar informações..."
            inputId="filtroMsg"
            value={filterInput}
            onChange={(e) => {
              setFilterInput(e.target.value);
            }}
          />
          <AiOutlineSearch size={30} color="gray" />
        </Col>
      </Row>

      <Row>
        <Col>
          <Accordion>
            {dataList && dataList.length > 0 ? (
              dataList.map((obj, idx) => (
                <Accordion.Item eventKey={idx.toString()}>
                  <AccordionHeader>
                    <ItemHeader>
                      <span>{"{"}</span>
                      <>
                        {Object.keys(obj).map((e, idx) => (
                          <Item
                            type={typeof obj[e]}
                            last={idx === Object.keys(obj).length - 1}
                          >
                            <span className="key">{e}</span>
                            <span>:</span>
                            <span className="value">
                              {typeof obj[e] === "string"
                                ? `"${decodeStr(e, obj[e])}"`
                                : !obj[e]
                                ? "null"
                                : obj[e]}
                            </span>
                            <span>
                              {idx !== Object.keys(obj).length - 1 && ","}
                            </span>
                          </Item>
                        ))}
                      </>
                      <span>{"}"}</span>
                    </ItemHeader>
                  </AccordionHeader>
                  <Accordion.Body className="d-flex flex-column">
                    <span>{"{"}</span>
                    <>
                      {Object.keys(obj).map((e, idx) => (
                        <Item
                          type={typeof obj[e]}
                          last={idx === Object.keys(obj).length - 1}
                        >
                          <span className="key">{e}</span>
                          <span>:</span>
                          <span className="value">
                            {typeof obj[e] === "string"
                              ? `"${decodeStr(e, obj[e])}"`
                              : !obj[e]
                              ? "null"
                              : obj[e]}
                          </span>
                          <span>
                            {idx !== Object.keys(obj).length - 1 && ","}
                          </span>
                        </Item>
                      ))}
                    </>
                    <span>{"}"}</span>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p>Nada encontrado...</p>
            )}
          </Accordion>
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default MessagesDetails;
