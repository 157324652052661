import { ChangeEvent, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./style.css";
import ButtonCustom from "../Button";

import { PiEyeDuotone, PiEyeClosedDuotone } from "react-icons/pi";

type InputProps = {
  type?: string;
  label?: string;
  placeholder: string;
  inputId: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  textarea?: boolean;
  rows?: number;
  className?: string;
};

const InputComponent = ({
  type = "text",
  label,
  placeholder,
  inputId,
  value,
  onChange,
  isInvalid = false,
  errorMsg,
  disabled = false,
  min,
  max,
  textarea = false,
  rows = 5,
  className,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form.Group controlId={inputId}>
      {label && <Form.Label>{label}</Form.Label>}
      {type === "password" ? (
        <InputGroup aria-disabled={disabled}>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            isInvalid={isInvalid}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={className ? className : ""}
          />
          <ButtonCustom
            className="password-btn"
            onClick={hidePassword}
            disabled={disabled}
          >
            {showPassword ? (
              <PiEyeDuotone color="#402392" size={26} />
            ) : (
              <PiEyeClosedDuotone color="#402392" size={26} />
            )}
          </ButtonCustom>
        </InputGroup>
      ) : textarea ? (
        <Form.Control
          type="text"
          placeholder={placeholder}
          isInvalid={isInvalid}
          value={value}
          onChange={onChange}
          disabled={disabled}
          as="textarea"
          rows={rows}
          className={className ? className : ""}
        />
      ) : (
        <Form.Control
          type={type}
          placeholder={placeholder}
          isInvalid={isInvalid}
          value={value}
          onChange={onChange}
          disabled={disabled}
          min={min}
          max={max}
          className={className ? className : ""}
        />
      )}
      <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputComponent;
