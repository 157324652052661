import { createBrowserRouter } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Agents from "./Pages/Agents";
import PageTemplate from "./Components/PageTemplate";
import Users from "./Pages/Users/Users";
import Messages from "./Pages/Messages";
import Requirements from "./Pages/Requirements";
import Protocols from "./Pages/Protocols";

export const routes = createBrowserRouter([
  { path: "/", element: <Login /> },
  {
    path: "/inicio",
    element: (
      <PageTemplate>
        <Dashboard />
      </PageTemplate>
    ),
  },
  {
    path: "/agentes",
    element: (
      <PageTemplate>
        <Agents />
      </PageTemplate>
    ),
  },
  {
    path: "/usuarios",
    element: (
      <PageTemplate>
        <Users />
      </PageTemplate>
    ),
  },
  {
    path: "/mensagens",
    element: (
      <PageTemplate>
        <Messages />
      </PageTemplate>
    ),
  },
  {
    path: "/requerimentos",
    element: (
      <PageTemplate>
        <Requirements />
      </PageTemplate>
    ),
  },
  {
    path: "/protocolos",
    element: (
      <PageTemplate>
        <Protocols />
      </PageTemplate>
    ),
  },
]);
