import { Dispatch, ReactNode, SetStateAction } from "react";
import { Modal } from "react-bootstrap";

import "./style.css";

interface IModalProps {
  show: boolean;
  closeFn: () => void;
  title?: string;
  children: ReactNode;
  hasFooter: boolean;
  footer?: ReactNode;
  size?: string;
}

const ModalContainer = ({
  show,
  closeFn,
  title,
  children,
  hasFooter,
  footer,
  size,
}: IModalProps) => {
  return (
    <Modal show={show} onHide={closeFn} className={size ? "modal-size-lg" : ""}>
      {title && (
        <Modal.Header closeButton>
          <h4 className="m-0">{title}</h4>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {hasFooter && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalContainer;
