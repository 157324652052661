import { ButtonGroup, Col, Row } from "react-bootstrap";
import TableCustom from "../../Components/Table";
import { useUsers } from "../../Hooks/useUsers";
import React, { useEffect, useMemo, useState } from "react";
import ButtonCustom from "../../Components/Button";

//icons
import { IoSyncSharp, IoAdd } from "react-icons/io5";
import { RiDeleteBin5Line, RiEdit2Line } from "react-icons/ri";

import "./style.css";
import SweetAlert from "../../Utils/AlertMessages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UsersModal from "./UsersModal";

const Users = () => {
  const navigate = useNavigate();
  const { getUsersList, usersList, getUser, deleteUser, isUsersLoading } =
    useUsers();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<"edit" | "add">("add");

  useEffect(() => {
    getUsersList();
  }, []);

  const tableColumns = [
    { dataField: "person", text: "Nome", sort: true },
    { dataField: "username", text: "Nome de usuário", sort: true },
    { dataField: "role", text: "Escopo", sort: true },
    { dataField: "acoes", text: "" },
  ];

  const handleEdit = (username: string) => {
    setModalType("edit");
    getUser(username);
    setShowModal(true);
  };

  const handleDelete = (username: string) => {
    const loggedUser = localStorage.getItem("USERNAME");

    if (username === loggedUser) {
      SweetAlert.default({
        title: "Deseja deletar sua conta?",
        text: "Essa ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar!",
        callback: () => {
          deleteUser(username, "self", navigate);
        },
      });
    } else {
      SweetAlert.default({
        title: "Deseja deletar o usuário?",
        text: "Essa ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar!",
        callback: () => {
          deleteUser(username);
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Row className="m-0 mt-sm-3 mt-lg-0 px-1 d-flex flex-column gap-2 gap-sm-0 flex-sm-row align-items-sm-center">
        <Col className="p-0 m-0">
          <h5 className="m-0">Lista de Usuários</h5>
        </Col>

        <Col className="m-0 p-0 d-flex justify-content-sm-end">
          <ButtonCustom
            onClick={() => {
              setModalType("add");
              setShowModal(true);
            }}
            className="d-flex align-items-center gap-2 py-1"
            disabled={isUsersLoading}
          >
            <IoAdd size={20} />
            <span>Criar Usuário</span>
          </ButtonCustom>

          <ButtonCustom
            onClick={() => getUsersList()}
            className="d-flex align-items-center gap-2 py-1"
            disabled={isUsersLoading}
          >
            <IoSyncSharp />
            <span>Atualizar</span>
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="m-0 px-1 table-users">
        <TableCustom
          columns={tableColumns}
          data={
            usersList.length > 0
              ? usersList.map(({ person, username, role }) => {
                  return {
                    person: person,
                    username: username,
                    role: role === "admin" ? "Administrador" : "Usuário",
                    acoes: (
                      <div className="d-flex align-items-center">
                        <ButtonCustom
                          variant="outline-secondary"
                          className="p-2 d-flex align-items-center"
                          onClick={() => handleEdit(username)}
                        >
                          <RiEdit2Line size={18} />
                        </ButtonCustom>

                        <ButtonCustom
                          variant="outline-danger"
                          className="p-2 d-flex align-items-center"
                          onClick={() => handleDelete(username)}
                        >
                          <RiDeleteBin5Line size={18} />
                        </ButtonCustom>
                      </div>
                    ),
                  };
                })
              : []
          }
          noDataIndication="Nenhum usuário encontrado..."
          keyField="id"
        />
      </Row>

      <UsersModal show={showModal} setShow={setShowModal} type={modalType} />
    </React.Fragment>
  );
};

export default Users;
