// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > thead
  > tr
  > th:nth-child(4),
.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > thead
  > tr
  > th:nth-child(5),
.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > thead
  > tr
  > th:last-child {
  width: 80px;
  text-align: center;
}

.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > tbody
  > tr
  > td:nth-child(4),
.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > tbody
  > tr
  > td:nth-child(5),
.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > tbody
  > tr
  > td:last-child {
  text-align: center;
}

.row.table-messages
  > .col
  > .react-bootstrap-table
  > table
  > thead
  > tr
  > th:first-child {
  width: 320px;
}

div.icon-error {
  color: var(--color-danger);
}

div.icon-noError {
  color: var(--color-success);
}

.details-button {
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;

  background-color: transparent;
  border: none;
  color: var(--color-primary);

  transition: ease-in-out 0.5s transform;
}

.details-button:hover {
  transform: scale(1.5);
}

.search-btn {
  width: 39px;
  height: 39px;
}

.btn.table-copy-btn {
  border: none;
  background-color: transparent;
}

.btn.table-copy-btn:hover {
  color: #19875469;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Requirements/style.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;EAqBE,WAAW;EACX,kBAAkB;AACpB;;AAEA;;;;;;;;;;;;;;;;;;;;;EAqBE,kBAAkB;AACpB;;AAEA;;;;;;;EAOE,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,WAAW;;EAEX,6BAA6B;EAC7B,YAAY;EACZ,2BAA2B;;EAE3B,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > thead\n  > tr\n  > th:nth-child(4),\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > thead\n  > tr\n  > th:nth-child(5),\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > thead\n  > tr\n  > th:last-child {\n  width: 80px;\n  text-align: center;\n}\n\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > tbody\n  > tr\n  > td:nth-child(4),\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > tbody\n  > tr\n  > td:nth-child(5),\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > tbody\n  > tr\n  > td:last-child {\n  text-align: center;\n}\n\n.row.table-messages\n  > .col\n  > .react-bootstrap-table\n  > table\n  > thead\n  > tr\n  > th:first-child {\n  width: 320px;\n}\n\ndiv.icon-error {\n  color: var(--color-danger);\n}\n\ndiv.icon-noError {\n  color: var(--color-success);\n}\n\n.details-button {\n  width: 30px;\n  height: 30px;\n  padding: 0px;\n  margin: 0px;\n\n  background-color: transparent;\n  border: none;\n  color: var(--color-primary);\n\n  transition: ease-in-out 0.5s transform;\n}\n\n.details-button:hover {\n  transform: scale(1.5);\n}\n\n.search-btn {\n  width: 39px;\n  height: 39px;\n}\n\n.btn.table-copy-btn {\n  border: none;\n  background-color: transparent;\n}\n\n.btn.table-copy-btn:hover {\n  color: #19875469;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
