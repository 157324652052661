import { Accordion } from "react-bootstrap";
import styled from "styled-components";

type ItemProps = {
  type: string;
  last: boolean;
};

export const AccordionHeader = styled(Accordion.Header)`
  > .accordion-button {
    padding: 10px 14px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;

  span {
    height: 20px;
  }
`;

export const Item = styled.div<ItemProps>`
  height: 20px;
  > .key {
    color: #2a2c2d;
  }

  > span:nth-child(2) {
    margin-right: 5px;
  }

  > .value {
    color: ${({ type }) =>
      type === "string" ? "#800" : type === "number" ? "#aa0d91" : "#1c00cf"};
  }

  > span:last-child {
    margin-right: ${({ last }) => (last ? "0px" : "8px")};
  }
`;
