import { RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Global.css";
import { routes } from "./Routes";
import { ToastContainer } from "react-toastify";
import { AgentProvider } from "./Hooks/useAgents";
import { UserProvider } from "./Hooks/useUsers";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <UserProvider>
        <AgentProvider>
          <RouterProvider router={routes} />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            theme="light"
          />
        </AgentProvider>
      </UserProvider>
    </>
  );
}

export default App;
