import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Api } from "../Utils/api";
import { toast } from "react-toastify";
import SweetAlert from "../Utils/AlertMessages";
import { Col, Row } from "react-bootstrap";
import ButtonCustom from "../Components/Button";
import { FaRegCopy, FaCheck } from "react-icons/fa6";
import { PiCheckCircleDuotone } from "react-icons/pi";

export type GetMessagesBodyReq = {
  agent: string;
  limit: number;
  order: string;
  offset?: number;
};

export type CreateRequirementReq = {
  id: string;
  agent: string;
  version: string;
  name: string;
  cnpj: string;
  payload: {
    call: string;
    data: string;
  };
};

export type CreateAgentBodyReq = {
  name: string;
  cnpj: string;
};

interface IAgentContext {
  isAgentsLoading: boolean;
  isProtocolLoading: boolean;
  agentsList: any[];
  agentMessagesList: any[];
  protocolsList: any[];
  requirementsList: any[];
  foundProtocol: any;
  getAgentsList: () => void;
  createAgent: (body: CreateAgentBodyReq, closeModal: () => void) => void;
  getAgentMessageList: (body: GetMessagesBodyReq) => void;
  getAgentProtocolsList: (
    body: GetMessagesBodyReq,
    setList?: Dispatch<SetStateAction<any[]>>
  ) => void;
  getRequirementsList: (body: GetMessagesBodyReq) => void;
  createRequirement: (body: CreateRequirementReq, closeFn: () => void) => void;
  getProtocol: (
    protocol: string,
    setList?: Dispatch<SetStateAction<any[]>>
  ) => void;
}

export const AgentContext = createContext<IAgentContext>({} as IAgentContext);

export const useAgents = () => {
  const context = useContext(AgentContext);

  if (!context) {
    throw new Error("useAgents must be within AgentProvider");
  }

  return context;
};

type AgentProviderProps = {
  children: ReactNode;
};

const ProtocolAlert = ({ protocol }: any) => {
  const [copied, setCopied] = useState(false);

  return (
    <div>
      <Row>
        <Col className="d-flex justify-content-center">
          <PiCheckCircleDuotone
            size={100}
            color="var(--toastify-color-success)"
          />
        </Col>
      </Row>
      <Row className="p-4 pt-3">
        <Col className="d-flex flex-column align-items-center gap-3">
          <span className="text-body-secondary fs-5">
            Protocolo criado com successo!
          </span>
          <div className="border rounded w-100 d-flex align-items-center justify-content-between">
            <span className="mx-2">{protocol}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const AgentProvider = ({ children }: AgentProviderProps) => {
  const [isAgentsLoading, setIsAgentsLoading] = useState(false);
  const [agentsList, setAgentsList] = useState<any>([]);
  const [agentMessagesList, setAgentMessagesList] = useState<any>([]);
  const [protocolsList, setProtocolsList] = useState<any>([]);
  const [requirementsList, setRequirementsList] = useState<any>([]);
  const [foundProtocol, setFoundProtocol] = useState<any>();
  const [isProtocolLoading, setIsProtocolLoading] = useState(false);

  const getAgentsList = () => {
    setIsAgentsLoading(true);
    Api.post("/server/agent/list")
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsAgentsLoading(false);
          if (res.data.Data) {
            setAgentsList(res.data.Data);
          } else {
            setAgentsList([]);
          }
        }
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const createAgent = (body: CreateAgentBodyReq, closeModal: () => void) => {
    setIsAgentsLoading(true);
    Api.post("/server/agent/create", body)
      .then(({ data }) => {
        setIsAgentsLoading(false);
        closeModal();
        getAgentsList();
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const getAgentMessageList = (body: GetMessagesBodyReq) => {
    setIsAgentsLoading(true);
    Api.post("/server/agent/messages", body)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsAgentsLoading(false);
          if (res.data.Data?.length > 0) {
            let aux = res.data.Data.map((e: any) => {
              return {
                ...e,
                payload: e.payload === "" ? e.payload : JSON.parse(e.payload),
              };
            });
            setAgentMessagesList(aux);
          } else {
            setAgentMessagesList([]);
          }
        }
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const getAgentProtocolsList = (
    body: GetMessagesBodyReq,
    setList?: Dispatch<SetStateAction<any[]>>
  ) => {
    setIsAgentsLoading(true);
    Api.post("/server/agent/protocols", body)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsAgentsLoading(false);
          if (res.data.Data?.length > 0) {
            let aux = res.data.Data.map((e: any) => {
              return {
                ...e,
                load: e.load === "" ? e.load : JSON.parse(e.load),
              };
            });
            aux = aux.map((e: any) => {
              return {
                protocol_id: e.id,
                created: e.created,
                id: e.load.id,
                call: e.load.payload.call,
                data: e.load.payload.data
                  ? JSON.parse(e.load.payload.data)
                  : [],
                error: e.load.payload.error ? e.load.payload.error : "",
              };
            });
            if (setList) setList(aux);
            setProtocolsList(aux);
          } else {
            setProtocolsList([]);
            if (setList) setList([]);
          }
        }
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        console.log(err);
      });
  };

  const getRequirementsList = (body: GetMessagesBodyReq) => {
    setIsAgentsLoading(true);
    Api.post("/server/agent/requirements", body)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsAgentsLoading(false);
          getAgentProtocolsList(body);
          if (res.data.Data?.length > 0) {
            let aux = res.data.Data.map((e: any) => {
              return {
                ...e,
                load: e.load === "" ? e.load : JSON.parse(e.load),
              };
            });
            aux = aux.map((e: any) => {
              return {
                req_id: e.id,
                created: e.created,
                id: e.load.id,
                call: e.load.payload.call,
                data: e.load.payload.data
                  ? JSON.parse(e.load.payload.data)
                  : [],
              };
            });
            setRequirementsList(aux);
          } else {
            setRequirementsList([]);
          }
        }
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        console.log(err);
      });
  };

  const getProtocol = (
    protocol: string,
    setList?: Dispatch<SetStateAction<any[]>>
  ) => {
    setIsProtocolLoading(true);
    Api.post("/agent/get/protocol", { protocol })
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsProtocolLoading(false);
          if (res.data.data) {
            let aux = JSON.parse(res.data.data);
            aux = {
              created: "",
              agent: aux.agent,
              id: aux.id,
              call: aux.payload.call,
              data: aux.payload.data ? JSON.parse(aux.payload.data) : [],
              error: aux.payload.error ? aux.payload.error : "",
            };
            setFoundProtocol(aux);
            if (setList) setList([aux]);
          } else {
            setFoundProtocol(null);
            if (setList) setList([]);
          }
        }
      })
      .catch((err) => {
        setIsProtocolLoading(false);
        console.log(err);
        toast.error(err.response.data.Error);
      });
  };

  const createRequirement = (
    body: CreateRequirementReq,
    closeFn: () => void
  ) => {
    setIsAgentsLoading(true);
    Api.post("/agent/send/requiment", body)
      .then(({ data }) => {
        setIsAgentsLoading(false);
        toast(<ProtocolAlert protocol={data.protocol} />, {
          autoClose: false,
          position: "top-center",
          closeOnClick: false,
          onClose: () => closeFn(),
        });
      })
      .catch((err) => {
        setIsAgentsLoading(false);
        console.log(err);
        SweetAlert.default({
          title: "Erro ao criar requerimento.",
          text: err?.response?.data?.error,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Ok!",
          callback: () => {},
        });
      });
  };

  return (
    <AgentContext.Provider
      value={{
        isAgentsLoading,
        agentsList,
        agentMessagesList,
        getAgentsList,
        createAgent,
        getAgentMessageList,
        protocolsList,
        getAgentProtocolsList,
        requirementsList,
        getRequirementsList,
        createRequirement,
        foundProtocol,
        getProtocol,
        isProtocolLoading,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};
