import { useAgents } from "../../Hooks/useAgents";
import React, { useEffect, useState } from "react";

//components
import { Col, Row } from "react-bootstrap";
import ButtonCustom from "../../Components/Button";

//icons
import { IoSyncSharp, IoAdd } from "react-icons/io5";
import CreateAgentModal from "./AgentModal";
import TableCustom from "../../Components/Table";

import "./style.css";
import { cnpjMask } from "../../Utils/Masks";

const Agents = () => {
  const { getAgentsList, agentsList, isAgentsLoading } = useAgents();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getAgentsList();
  }, []);

  const tableColumns = [
    { dataField: "id", text: <span className="th-sm">ID</span>, sort: true },
    {
      dataField: "name",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "cnpj",
      text: "CNPJ",
      sort: true,
    },
    { dataField: "uuid", text: "UUID", sort: false },
  ];

  return (
    <React.Fragment>
      <Row className="m-0 mt-sm-3 mt-lg-0 px-1 d-flex flex-column gap-2 gap-sm-0 flex-sm-row align-items-sm-center">
        <Col className="p-0 m-0">
          <h5 className="m-0">Lista de Agentes</h5>
        </Col>

        <Col className="m-0 p-0 d-flex justify-content-sm-end">
          <ButtonCustom
            onClick={() => setShowModal(true)}
            className="d-flex align-items-center gap-2 py-1"
            disabled={isAgentsLoading}
          >
            <IoAdd size={20} />
            <span>Criar Agente</span>
          </ButtonCustom>

          <ButtonCustom
            onClick={() => getAgentsList()}
            className="d-flex align-items-center gap-2 py-1"
            disabled={isAgentsLoading}
          >
            <IoSyncSharp />
            <span>Atualizar</span>
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="m-0 px-1 table-agents">
        <TableCustom
          keyField="id"
          columns={tableColumns}
          noDataIndication="Nenhum agente encontrado..."
          data={
            agentsList.length > 0
              ? agentsList.map(({ name, cnpj, id, uuid }) => {
                  return {
                    name,
                    cnpj: cnpjMask(cnpj),
                    id,
                    uuid,
                  };
                })
              : []
          }
        />
      </Row>

      <CreateAgentModal show={showModal} setShow={setShowModal} />
    </React.Fragment>
  );
};

export default Agents;
