import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useAgents } from "../../Hooks/useAgents";
import SelectComponent from "../../Components/Select";
import InputComponent from "../../Components/Input";
import ButtonCustom from "../../Components/Button";

import { AiOutlineSearch } from "react-icons/ai";
import TableCustom from "../../Components/Table";
import { IoIosMore } from "react-icons/io";
import moment from "moment";
import "./style.css";
import MessagesDetails from "./MessagesModal";

const Messages = () => {
  const {
    getAgentsList,
    agentsList,
    getAgentMessageList,
    agentMessagesList,
    isAgentsLoading,
  } = useAgents();
  const [uuid, setUuid] = useState("30d75f0c-2530-49a3-9b06-66ea8eb3f147"); //belluno
  const [limit, setLimit] = useState("5");
  const [order, setOrder] = useState("desc");
  const [offset, setOffset] = useState("0");
  const [inputErr, setInputErr] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState({
    created: "",
    payload: {
      id: "",
      call: "",
      error: "",
      data: [],
    },
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getAgentsList();
  }, []);

  const options = useMemo(() => {
    return agentsList.map(({ uuid, name }) => {
      return { id: uuid, label: name };
    });
  }, [agentsList]);

  const messages = useMemo(() => {
    return agentMessagesList.map(({ payload, created }) => {
      return {
        created,
        payload: {
          id: payload.id,
          call: payload.payload.call,
          error: payload.payload.error,
          data: payload.payload.data ? JSON.parse(payload.payload.data) : [],
        },
      };
    });
  }, [agentMessagesList]);

  const handleSearch = () => {
    if (Number(limit) <= 0 || Number(offset) < 0 || !uuid || !order) {
      setInputErr(true);
    } else {
      let body = {
        agent: uuid,
        limit: Number(limit),
        offset: offset ? Number(offset) : 0,
        order: order ? order : "desc",
      };
      getAgentMessageList(body);
    }
  };

  useEffect(() => {
    if (uuid !== "") handleSearch();
  }, []);

  useEffect(() => {}, [agentMessagesList, messages]);

  const msgTableColumns = [
    {
      dataField: "id",
      text: "Protocolo",
      sort: true,
    },

    {
      dataField: "created",
      text: "Recebida",
      sort: true,
    },
    {
      dataField: "call",
      text: "Método",
      sort: true,
    },
    {
      dataField: "data",
      text: "Items",
      sort: true,
    },
    {
      dataField: "acoes",
      text: "",
    },
  ];

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Mais detalhes
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Row className="mt-sm-3 mt-lg-0 d-flex align-itens-center">
        <Col lg={3}>
          <SelectComponent
            placeholder="Selecione um agente..."
            options={options}
            setSelectedItem={setUuid}
            selectedItem={uuid}
            disabled={isAgentsLoading || options.length <= 0}
            label="Agentes"
            isInvalid={inputErr && !uuid}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Limite"
            placeholder="Limite de mensagens..."
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            inputId="limit"
            min={1}
            isInvalid={inputErr && Number(limit) <= 0}
            errorMsg="Deve ser maior que 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <InputComponent
            type="number"
            label="Offset"
            placeholder="Offset..."
            value={offset}
            onChange={(e) => {
              setOffset(e.target.value);
            }}
            inputId="offset"
            min={0}
            isInvalid={inputErr && Number(limit) < 0}
            errorMsg="Deve ser maior que ou igual a 0."
            disabled={isAgentsLoading}
          />
        </Col>

        <Col>
          <SelectComponent
            placeholder="Selecione uma ordem..."
            options={[
              { id: "desc", label: "Descendente" },
              { id: "asc", label: "Ascendente" },
            ]}
            setSelectedItem={setOrder}
            selectedItem={order}
            disabled={isAgentsLoading}
            label="Ordem"
            isInvalid={inputErr && !order}
            errorMsg="Campo Obrigatório."
            width="100%"
          />
        </Col>

        <Col sm={1} className="d-flex align-items-end">
          <ButtonCustom
            className="p-2 d-flex align-items-center justify-content-center search-btn"
            onClick={handleSearch}
            disabled={isAgentsLoading}
          >
            {isAgentsLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            ) : (
              <AiOutlineSearch size={22} />
            )}
          </ButtonCustom>
        </Col>
      </Row>

      <Row className="m-0 mt-sm-3 px-1 d-flex flex-column gap-2 gap-sm-0 flex-sm-row align-items-sm-center">
        <Col className="p-0 m-0">
          <h5 className="m-0">Lista de Mensagens</h5>
        </Col>
      </Row>

      <Row className="m-0 px-1 table-messages">
        {isAgentsLoading ? (
          <Col className="mt-5 w-100 d-flex align-items-center justify-content-center gap-2">
            <Spinner animation="border" />
            <span className="fs-5">Carregando...</span>{" "}
          </Col>
        ) : (
          <TableCustom
            keyField="id"
            columns={msgTableColumns}
            noDataIndication="Nenhuma mensagem encontrada..."
            data={
              messages.length > 0
                ? messages.map((e) => {
                    return {
                      id: e.payload.id,
                      created: moment
                        .utc(e.created)
                        .format("DD/MM/YY HH:mm:ss"),
                      call: e.payload.call,
                      data: e.payload.data.length,
                      acoes: (
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <button
                            className="details-button"
                            onClick={() => {
                              setSelectedMsg(e);
                              setShowModal(true);
                            }}
                          >
                            <IoIosMore size={30} />
                          </button>
                        </OverlayTrigger>
                      ),
                    };
                  })
                : []
            }
          />
        )}
      </Row>

      {showModal && (
        <MessagesDetails
          show={showModal}
          setShow={setShowModal}
          message={selectedMsg}
        />
      )}
    </React.Fragment>
  );
};

export default Messages;
