import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import ModalContainer from "../../Components/Modal";
import { Col, Row } from "react-bootstrap";
import ButtonCustom from "../../Components/Button";
import { useAgents } from "../../Hooks/useAgents";
import { LuX, LuSend } from "react-icons/lu";
import SelectComponent from "../../Components/Select";
import { v4 as uuidv4 } from "uuid";
import InputComponent from "../../Components/Input";

interface IAddModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setReqUuid: Dispatch<SetStateAction<string>>;
  setReqLimit: Dispatch<SetStateAction<string>>;
  setReqOffset: Dispatch<SetStateAction<string>>;
  setReqOrder: Dispatch<SetStateAction<string>>;
  agentsList: any[];
}

const AddModal = ({
  show,
  setShow,
  setReqLimit,
  setReqOffset,
  setReqOrder,
  setReqUuid,
  agentsList,
}: IAddModalProps) => {
  const { isAgentsLoading, createRequirement } = useAgents();
  const [uuid, setUuid] = useState("");
  const [query, setQuery] = useState("");
  const [limit, setLimit] = useState("1");
  const [offset, setOffset] = useState("0");
  const [inputErr, setInputErr] = useState(false);
  const [queryErr, setQueryErr] = useState("");

  const handleClose = () => {
    setQuery("");
    setUuid("");
    setQueryErr("");
    setInputErr(false);
    setShow(false);
  };

  const handleSave = () => {
    let queryArr = query.toUpperCase().split(" ");
    if (uuid === "" || query === "") {
      if (uuid === "") setInputErr(true);
      if (query === "") setQueryErr("Campo Obrigatório.");
    } else if (
      !queryArr.includes("SELECT") ||
      !queryArr.includes("FROM") ||
      queryArr[queryArr.length - 1] === "FROM"
    ) {
      setQueryErr("SQL deve seguir o exemplo: 'SELECT * FROM motorista'.");
    } else {
      setQueryErr("");
      setInputErr(false);
      const selectedAgent = options.find(({ id }) => id === uuid);
      let body = {
        id: uuidv4(),
        agent: selectedAgent?.id,
        version: "1.0",
        name:
          selectedAgent?.label[0].toUpperCase() + selectedAgent?.label.slice(1),
        cnpj: selectedAgent?.cnpj,
        payload: {
          call: "AgentSelectQuery",
          data: `{\"query\": \"${query}\",\"limit\": \"${limit}\", \"offset\": \"${offset}\"}`,
        },
      };

      // console.log("body", body);
      setReqLimit("20");
      setReqOffset("0");
      setReqOrder("desc");
      setReqUuid(uuid);
      createRequirement(body, handleClose);
    }
  };

  const options = useMemo(() => {
    return agentsList.map(({ uuid, name, cnpj }) => {
      return { id: uuid, label: name, cnpj };
    });
  }, [agentsList]);

  useEffect(() => {
    // toast();
  }, []);

  return (
    <>
      <ModalContainer
        show={show}
        closeFn={handleClose}
        title="Novo Requerimento"
        hasFooter
        footer={
          <Row className="m-0">
            <Col>
              <ButtonCustom
                onClick={handleSave}
                className="d-flex align-items-center gap-2 py-2"
                isLoading={isAgentsLoading}
                disabled={isAgentsLoading}
              >
                <LuSend />
                <span>Enviar</span>
              </ButtonCustom>
            </Col>

            <Col>
              <ButtonCustom
                className="d-flex align-items-center gap-2 py-2"
                variant="danger"
                onClick={handleClose}
                disabled={isAgentsLoading}
              >
                <LuX />
                <span>Cancelar</span>
              </ButtonCustom>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col>
            <SelectComponent
              placeholder="Selecione um agente..."
              options={options}
              setSelectedItem={setUuid}
              selectedItem={uuid}
              disabled={isAgentsLoading || options.length <= 0}
              isInvalid={inputErr}
              errorMsg="Campo Obrigatório."
              width="100%"
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <InputComponent
              label="Limite"
              placeholder="Limite"
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              inputId="add-limite"
              disabled={isAgentsLoading}
            />
          </Col>

          <Col>
            <InputComponent
              label="Offset"
              placeholder="Offset"
              value={offset}
              onChange={(e) => {
                setOffset(e.target.value);
              }}
              inputId="add-limite"
              disabled={isAgentsLoading}
            />
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <InputComponent
              textarea
              label="SQL"
              placeholder="SELECT * FROM motorista WHERE..."
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              inputId="query"
              disabled={isAgentsLoading}
              isInvalid={queryErr !== ""}
              errorMsg={queryErr}
            />
          </Col>
        </Row>
      </ModalContainer>
    </>
  );
};

export default AddModal;
