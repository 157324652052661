import { ReactNode } from "react";

//components
import { Button, Spinner } from "react-bootstrap";

//style
import "./style.css";

type ButtonProps = {
  children: ReactNode;
  type?: "button" | "submit" | "reset";
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  variant?: string;
  className?: string;
};

const ButtonCustom = ({
  children,
  type = "button",
  onClick,
  isLoading = false,
  disabled = false,
  variant = "primary",
  className = "",
}: ButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      variant={variant}
      className={
        isLoading
          ? "w-100 d-flex align-items-center justify-content-center gap-2"
          : className
      }
    >
      {isLoading ? (
        <>
          <Spinner animation="border" size="sm" />
          <span>Aguarde...</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonCustom;
