// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .modal-size-lg > .modal-dialog {
    max-width: 75%;
    height: 90%;
    overflow-y: auto;
  }
}
@media (min-width: 1024px) {
  .modal-size-lg > .modal-dialog {
    max-width: 75%;
  }
}

/* @media (min-width: 768px) {
  .modal-size-lg .modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .modal-size-lg .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/Components/Modal/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,cAAc;IACd,WAAW;IACX,gBAAgB;EAClB;AACF;AACA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;;;;;;;;;;;;GAYG","sourcesContent":["@media (min-width: 768px) {\n  .modal-size-lg > .modal-dialog {\n    max-width: 75%;\n    height: 90%;\n    overflow-y: auto;\n  }\n}\n@media (min-width: 1024px) {\n  .modal-size-lg > .modal-dialog {\n    max-width: 75%;\n  }\n}\n\n/* @media (min-width: 768px) {\n  .modal-size-lg .modal-body {\n    max-height: 500px;\n    overflow-y: auto;\n  }\n}\n\n@media (min-width: 1024px) {\n  .modal-size-lg .modal-body {\n    max-height: 600px;\n    overflow-y: auto;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
