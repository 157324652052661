//hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import { Card, Col, Row } from "react-bootstrap";
import ButtonCustom from "../../Components/Button";
import InputComponent from "../../Components/Input";
import logoBootclient from "../../Assets/LogoBootclient.svg";

//style
import BootclientLogo from "../../Components/MainLogo";
import { LoginCard, LoginPage } from "./style";
import { useUsers } from "../../Hooks/useUsers";
import { Api } from "../../Utils/api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameIsInvalid, setUsernameIsInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    setIsLoading(true);
    if (username === "" || password === "") {
      setUsernameIsInvalid(username === "");
      setPasswordIsInvalid(password === "");
      setIsLoading(false);
    } else {
      const body = {
        username,
        password,
      };
      Api.post("/login/read", body)
        .then(({ data }) => {
          setIsLoading(false);
          localStorage.setItem("USER", data.Data.person);
          localStorage.setItem("ROLE", data.Data.role);
          localStorage.setItem("USERNAME", data.Data.username);
          toast.success("Login realizado com sucesso!");
          navigate("inicio");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.Error);
          console.log(err.response);
        });
    }
  };

  return (
    <LoginPage>
      <LoginCard className="login-card">
        <Row>
          <Col className="d-flex justify-content-center">
            <BootclientLogo maxWidth="200px" type="default" />
          </Col>
        </Row>

        <Row className="d-flex flex-column my-4 gap-3">
          <Col className="mb-3">
            <InputComponent
              label="Usuário"
              placeholder="Digite o usuário..."
              isInvalid={usernameIsInvalid}
              errorMsg="Campo Obrigatório."
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              inputId="username"
              disabled={isLoading}
            />
          </Col>

          <Col className="mb-3">
            <InputComponent
              type="password"
              label="Senha"
              placeholder="Digite a senha..."
              isInvalid={passwordIsInvalid}
              errorMsg="Campo Obrigatório."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputId="password"
              disabled={isLoading}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ButtonCustom
              onClick={handleLogin}
              isLoading={isLoading}
              disabled={isLoading}
              className="w-100 m-0"
            >
              Entrar
            </ButtonCustom>
          </Col>
        </Row>
      </LoginCard>
    </LoginPage>
  );
};

export default Login;
