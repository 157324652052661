import { Card } from "react-bootstrap";
import styled from "styled-components";

export const LoginPage = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginCard = styled(Card)`
  width: 70%;
  padding: 30px 15px;

  @media (min-width: 768px) {
    width: 50%;
    padding: 50px 60px;
  }

  @media (min-width: 1024px) {
    width: 35%;
    padding: 50px 80px;
  }
`;
