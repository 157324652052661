import { Dropdown, Form } from "react-bootstrap";
import "./style.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type option = {
  id: string;
  label: string;
};

interface ISelectProps {
  placeholder: string;
  label?: string;
  options: option[];
  disabled?: boolean;
  width?: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  selectedItem?: string;
  isInvalid?: boolean;
  errorMsg?: string;
}

const SelectComponent = ({
  placeholder,
  label,
  options,
  disabled,
  width,
  setSelectedItem,
  selectedItem,
  isInvalid,
  errorMsg,
}: ISelectProps) => {
  const handleSelect = (e: any) => {
    setSelectedItem(e);
  };

  return (
    <>
      {label && <Form.Label className="dropdown-label">{label}</Form.Label>}
      <Dropdown onSelect={(e) => handleSelect(e)}>
        <Dropdown.Toggle
          disabled={options.length === 0 || disabled}
          style={{ width: width }}
          className={`m-0 d-flex align-items-center justify-content-between${
            isInvalid ? " border border-danger" : ""
          }`}
        >
          {options.length <= 0
            ? placeholder
            : selectedItem && selectedItem !== ""
            ? options.find(({ id }) => id === selectedItem)!.label
            : placeholder}
        </Dropdown.Toggle>

        {options.length > 0 && (
          <Dropdown.Menu>
            <Dropdown.ItemText>{placeholder}</Dropdown.ItemText>
            <Dropdown.Divider></Dropdown.Divider>

            {options.map(({ id, label }, idx) => (
              <Dropdown.Item key={idx} eventKey={id} disabled={disabled}>
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
      {isInvalid && (
        <span className="invalid-feedback d-inline">{errorMsg}</span>
      )}
    </>
  );
};

export default SelectComponent;
