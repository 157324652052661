import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Api } from "../Utils/api";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";

export type CreateUserBodyReq = {
  username: string;
  role: string;
  person: string;
  password: string;
};

export type User = {
  id: number;
  username: string;
  role: string;
  person: string;
  password: string;
};

interface IUserContext {
  isUsersLoading: boolean;
  usersList: any[];
  user: User | undefined;
  getUsersList: () => void;
  createUser: (body: CreateUserBodyReq, closeModal: () => void) => void;
  deleteUser: (
    username: string,
    type?: "self",
    navigate?: NavigateFunction
  ) => void;
  updateUser: (body: CreateUserBodyReq, closeModal: () => void) => void;

  getUser: (username: string) => void;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const useUsers = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUsers must be within UserProvider");
  }

  return context;
};

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [user, setUser] = useState<User>();

  const getUsersList = () => {
    setIsUsersLoading(true);
    Api.post("/login/user/list")
      .then(({ data }) => {
        setIsUsersLoading(false);
        setUsersList(data.Data);
      })
      .catch((err) => {
        setIsUsersLoading(false);
        console.log(err);
      });
  };

  const getUser = (username: string) => {
    setIsUsersLoading(true);
    const body = { username };
    Api.post("/login/read/user", body)
      .then(({ data }) => {
        setIsUsersLoading(false);
        setUser(data.Data);
      })
      .catch((err) => {
        setIsUsersLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const createUser = (body: CreateUserBodyReq, closeModal: () => void) => {
    setIsUsersLoading(true);
    Api.post("/login/create", body)
      .then(({ data }) => {
        setIsUsersLoading(false);
        toast.success("Usuário criado com sucesso!");
        closeModal();
        getUsersList();
      })
      .catch((err) => {
        setIsUsersLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const updateUser = (body: CreateUserBodyReq, closeModal: () => void) => {
    setIsUsersLoading(true);
    Api.post("/login/update", body)
      .then(({ data }) => {
        setIsUsersLoading(false);
        toast.success("Usuário atualizado com sucesso!");
        closeModal();
        getUsersList();
      })
      .catch((err) => {
        setIsUsersLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  const deleteUser = (
    username: string,
    type?: "self",
    navigate?: NavigateFunction
  ) => {
    setIsUsersLoading(true);
    Api.post("/login/delete", { username })
      .then(({ data }) => {
        setIsUsersLoading(false);
        toast.success("Usuário removido com sucesso!");
        if (navigate && type === "self") {
          localStorage.clear();
          navigate("/");
        } else {
          getUsersList();
        }
      })
      .catch((err) => {
        setIsUsersLoading(false);
        toast.error(err.response.data.Error);
        console.log(err);
      });
  };

  return (
    <UserContext.Provider
      value={{
        isUsersLoading,
        usersList,
        getUsersList,
        createUser,
        deleteUser,
        updateUser,
        getUser,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
