import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalContainer from "../../Components/Modal";
import { Accordion, Alert, Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { AccordionHeader, Item, ItemHeader, QueryDiv } from "./modalStyle";
import { IoWarningOutline } from "react-icons/io5";
import InputComponent from "../../Components/Input";
import { AiOutlineSearch } from "react-icons/ai";

interface IProtocolsDetailsProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  protocol: {
    created: string;
    id: string;
    call: string;
    data: any[];
    error: string;
  };
  requirementQuery: any;
}

const ProtocolsDetails = ({
  show,
  setShow,
  protocol,
  requirementQuery,
}: IProtocolsDetailsProps) => {
  const [filterInput, setFilterInput] = useState("");
  const [dataList, setDataList] = useState<any[]>(protocol.data);
  const handleClose = () => {
    setShow(false);
  };

  const handleFilterData = (value: string) => {
    if (value === "") {
      setDataList(protocol.data);
    } else {
      const filteredData = protocol.data.filter((obj) => {
        let values = Object.values(obj).join(" ").toLowerCase();
        return values.includes(value.toLowerCase());
      });

      setDataList(filteredData);
    }
  };

  const decodeStr = (field: string, value: string) => {
    if (
      field === "veiculoproprio" ||
      field === "tara" ||
      field === "statusrntrc" ||
      field === "mediainversa" ||
      field === "liberadoordemcar" ||
      field === "liberado" ||
      field === "incluirateio" ||
      field === "enviaris" ||
      field === "bloqueiomanifestomanutprev" ||
      field === "bloqueadoadm" ||
      field === "adtomotbloqueado" ||
      field === "funcionario"
    ) {
      return atob(value);
    } else {
      return value;
    }
  };

  useEffect(() => {}, [dataList]);

  useEffect(() => {
    handleFilterData(filterInput);
  }, [filterInput]);

  return (
    <ModalContainer
      show={show}
      closeFn={handleClose}
      hasFooter={false}
      size="lg"
      title={`${protocol.call} - ${moment(protocol.created).format(
        "DD/MM/YY HH:mm:ss"
      )} - ${protocol.id}`}
    >
      {protocol.error !== "" && (
        <Row className="mx-0">
          <Col className="p-0">
            <Alert variant="danger" className="d-flex gap-2">
              <div>
                <IoWarningOutline size={28} />
              </div>
              <span>{protocol.error}</span>
            </Alert>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        {(requirementQuery?.limit || requirementQuery?.offset) && (
          <Col sm={3}>
            {requirementQuery.limit && (
              <p className="m-0 fw-bold">
                Limite:{" "}
                <span className="fw-normal">{requirementQuery.limit}</span>
              </p>
            )}
            {requirementQuery.offset && (
              <p className="m-0 fw-bold">
                Offset:{" "}
                <span className="fw-normal">{requirementQuery.offset}</span>
              </p>
            )}
            {requirementQuery.order && (
              <p className="m-0 fw-bold">
                Order:{" "}
                <span className="fw-normal">
                  {requirementQuery.order === "asc"
                    ? "Ascendente"
                    : "Descendente"}
                </span>
              </p>
            )}
          </Col>
        )}
        <Col>
          <p className="m-0 fw-bold">Query:</p>
          <QueryDiv>
            <span> {requirementQuery?.query} </span>
          </QueryDiv>
        </Col>
      </Row>

      <Row className="mx-0 mb-3 d-flex align-items-center">
        <Col md={5} className="p-0 mr-2">
          <InputComponent
            placeholder="Procurar informações..."
            inputId="filtroMsg"
            value={filterInput}
            onChange={(e) => {
              setFilterInput(e.target.value);
            }}
          />
        </Col>
        <Col sm={1}>
          <AiOutlineSearch size={30} color="gray" />
        </Col>
      </Row>

      <Row>
        <Col>
          <Accordion>
            {dataList && dataList.length > 0 ? (
              dataList.map((obj, idx) => (
                <Accordion.Item eventKey={idx.toString()}>
                  <AccordionHeader>
                    <ItemHeader>
                      <span>{"{"}</span>
                      <>
                        {Object.keys(obj).map((e, idx) => (
                          <Item
                            type={typeof obj[e]}
                            last={idx === Object.keys(obj).length - 1}
                          >
                            <span className="key">{e}</span>
                            <span>:</span>
                            <span className="value">
                              {typeof obj[e] === "string"
                                ? `"${decodeStr(e, obj[e])}"`
                                : !obj[e]
                                ? "null"
                                : obj[e]}
                            </span>
                            <span>
                              {idx !== Object.keys(obj).length - 1 && ","}
                            </span>
                          </Item>
                        ))}
                      </>
                      <span>{"}"}</span>
                    </ItemHeader>
                  </AccordionHeader>
                  <Accordion.Body className="d-flex flex-column">
                    <span>{"{"}</span>
                    <>
                      {Object.keys(obj).map((e, idx) => (
                        <Item
                          type={typeof obj[e]}
                          last={idx === Object.keys(obj).length - 1}
                        >
                          <span className="key">{e}</span>
                          <span>:</span>
                          <span className="value">
                            {typeof obj[e] === "string"
                              ? `"${decodeStr(e, obj[e])}"`
                              : !obj[e]
                              ? "null"
                              : obj[e]}
                          </span>
                          <span>
                            {idx !== Object.keys(obj).length - 1 && ","}
                          </span>
                        </Item>
                      ))}
                    </>
                    <span>{"}"}</span>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p>Nada encontrado...</p>
            )}
          </Accordion>
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default ProtocolsDetails;
