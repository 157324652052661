import axios from "axios";
import { Report } from "powerbi-report-component";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import "./style.css";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState({
    accessToken: "",
    reportId: "",
    reportName: "",
    embedUrl: "",
  });

  const getDashboardData = () => {
    setLoading(true);
    axios({
      method: "post",
      url: "https://bi.novelconsultoria.com.br/_api/powerbi/getEmbedToken",
      data: {
        workspace_id: "5f8795a9-13d0-4a13-a1e8-2cd1fff9bf4b",
        report_id: "527aea24-c11f-46e6-9aee-7989a9cdb426",
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          setCurrentData({
            accessToken: data?.accessToken,
            reportId: data.embedUrl[0]?.reportId,
            embedUrl: data.embedUrl[0]?.embedUrl,
            reportName: data.embedUrl[0]?.reportName,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error("Erro ao carregar powerbi.");
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Col className="w-100 mt-5 d-flex align-items-center justify-content-center gap-3">
            <Spinner variant="primary" animation="border" role="status" />
            <span className="loading-text">Carregando...</span>
          </Col>
        </Row>
      ) : currentData && currentData.accessToken ? (
        <Report
          key={currentData.reportId}
          reportMode="View"
          tokenType="Embed"
          accessToken={currentData?.accessToken}
          embedUrl={currentData.embedUrl}
          embedId={currentData.reportId}
          permissions="All"
          style={{
            width: "100%",
            height: "100%",
            border: 0,
            backgroundColor: "#f3f3f3",
          }}
        />
      ) : (
        <Row>
          <Col>
            <h5>Nenhuma informação encontrada!</h5>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
