import { Col } from "react-bootstrap";
import styled from "styled-components";

interface ColProps {
  hideMenu: boolean;
  url?: string;
}

export const NavCol = styled(Col)<ColProps>`
  width: 100%;
  padding: 0;
  margin: 0;

  @media (min-width: 1024px) {
    max-width: ${({ hideMenu }) => (hideMenu ? "5vw" : "20vw")};
    height: 100vh;
  }
`;

export const ContentCol = styled.main<ColProps>`
  width: 100%;
  padding: 0;
  margin: 0;

  @media (min-width: 1024px) {
    width: ${({ hideMenu }) => (hideMenu ? "95vw" : "80vw")};
    max-width: ${({ hideMenu }) => (hideMenu ? "95vw" : "80vw")};
    height: 100vh;
    max-height: 100vh;
    overflow: ${({ url }) => (url === "/inicio" ? "hidden" : "auto")};

    padding: ${({ url }) => (url === "/inicio" ? "0px" : "50px 20px")};
  }
`;
