import { Nav, Navbar, NavbarText } from "react-bootstrap";
import styled from "styled-components";

type NavLinkProps = {
  active: boolean;
};

type NavUserProps = {
  hideMenu: boolean;
};

export const CustomNavbar = styled(Navbar)`
  width: 100%;
  height: fit-content;
  max-height: 40vh;
  padding: 20px 0px 0px;

  display: flex;
  align-items: start;

  background-color: var(--color-primary);
  color: var(--color-white);
  box-sizing: border-box;

  > .container {
    padding: 0px 10px 5px;
    display: flex;
  }

  @media (min-width: 1024px) {
    height: 100vh;
    max-height: 100vh;
    padding: 50px 0px 30px;

    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > .container {
      flex-direction: column;
    }
  }
`;

export const NavContent = styled.nav`
  width: 100%;
  margin-top: 10px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  background-color: var(--color-primary);

  @media (min-width: 1024px) {
    flex-direction: column;
    max-height: 65vh;
    overflow-y: auto;
    margin-top: 15px;
    flex-wrap: nowrap;
  }
`;

export const NavLink = styled.a<NavLinkProps>`
  width: fit-content;
  padding: 5px 10px;

  text-decoration: none;
  color: ${({ active }) =>
    active ? "var(--color-active)" : "var(--color-white)"};
  background-color: ${({ active }) =>
    active ? "var(--bg-color)" : "var(--color-primary)"};

  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: var(--bg-color);
    color: var(--color-primary);
  }

  @media (min-width: 1024px) {
    width: 100%;
    border-bottom: 1px solid var(--color-gray-0);
  }
`;

export const NavUser = styled(NavbarText)<NavUserProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;

    > div {
      display: flex;
      flex-direction: column;

      > p {
        margin: 0;
        font-size: 1rem;
        line-height: 1rem;
        color: var(--color-white);
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > span {
        margin-top: 1px;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  @media (min-width: 1024px) {
    margin: ${({ hideMenu }) => (hideMenu ? "10px 0px 0px" : "30px 0px 0px")};
  }
`;
