import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ModalContainer from "../../Components/Modal";
import ButtonCustom from "../../Components/Button";

//icons
import { LuX, LuSave } from "react-icons/lu";
import InputComponent from "../../Components/Input";
import { useUsers } from "../../Hooks/useUsers";

interface IModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  type: "edit" | "add";
}

const UsersModal = ({ show, setShow, type }: IModalProps) => {
  const { updateUser, createUser, isUsersLoading, user } = useUsers();
  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState({ error: false, msg: "" });
  const [role, setRole] = useState("");
  const [person, setPerson] = useState("");
  const [personErr, setPersonErr] = useState({ error: false, msg: "" });
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState({ error: false, msg: "" });

  const handleClose = () => {
    if (!isUsersLoading) {
      setShow(false);
      setUsername("");
      setRole("");
      setPerson("");
      setPassword("");
      setPasswordErr({ error: false, msg: "" });
      setPersonErr({ error: false, msg: "" });
      setUsernameErr({ error: false, msg: "" });
    }
  };

  const fieldsHasErros = () => {
    let errors = [];
    if (username === "") {
      setUsernameErr({ error: true, msg: "Campo obrigatório." });
      errors.push(true);
    }
    if (person === "") {
      setPersonErr({ error: true, msg: "Campo obrigatório." });
      errors.push(true);
    }
    if (password === "") {
      setPasswordErr({ error: true, msg: "Campo obrigatório." });
      errors.push(true);
    }

    if (username.length < 8) {
      setUsernameErr({
        error: true,
        msg: "Deve conter no mínimo 8 caracteres.",
      });
      errors.push(true);
    }
    if (person.length < 8) {
      setPersonErr({
        error: true,
        msg: "Deve conter no mínimo 8 caracteres.",
      });
      errors.push(true);
    }
    if (password.length < 8) {
      setPasswordErr({
        error: true,
        msg: "Deve conter no mínimo 8 caracteres.",
      });
      errors.push(true);
    }

    return errors.length > 0;
  };

  const handleSave = () => {
    if (!fieldsHasErros()) {
      let body = {
        username,
        person,
        role,
        password,
      };
      if (type === "edit") {
        updateUser(body, handleClose);
      } else {
        createUser(body, handleClose);
      }
    }
  };

  useEffect(() => {
    if (type === "edit") {
      setUsername(user ? user.username : "");
      setRole(user ? user.role : "");
      setPerson(user ? user.person : "");
      setPassword(user ? user.password : "");
    }
  }, [user, type]);

  return (
    <ModalContainer
      show={show}
      closeFn={handleClose}
      title={`${type === "edit" ? "Atualizar" : "Adicionar"} Usuário`}
      hasFooter={true}
      footer={
        <Row className="m-0">
          <Col>
            <ButtonCustom
              onClick={handleSave}
              className="d-flex align-items-center gap-2 py-2"
              isLoading={isUsersLoading}
              disabled={isUsersLoading}
            >
              <LuSave />
              <span>Salvar</span>
            </ButtonCustom>
          </Col>

          <Col>
            <ButtonCustom
              className="d-flex align-items-center gap-2 py-2"
              variant="danger"
              onClick={handleClose}
              disabled={isUsersLoading}
            >
              <LuX />
              <span>Cancelar</span>
            </ButtonCustom>
          </Col>
        </Row>
      }
    >
      <Row className="mb-3">
        <InputComponent
          label="Username"
          placeholder="Digite o username..."
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            if (e.target.value.length >= 8)
              setUsernameErr({ error: false, msg: "" });
          }}
          inputId="username"
          disabled={type === "edit"}
          isInvalid={usernameErr.error}
          errorMsg={usernameErr.msg}
        />
      </Row>

      <Row className="mb-3">
        <InputComponent
          label="Nome"
          placeholder="Digite o nome do usuário..."
          value={person}
          onChange={(e) => {
            setPerson(e.target.value);
            if (e.target.value.length >= 8)
              setPersonErr({ error: false, msg: "" });
          }}
          inputId="person"
          disabled={isUsersLoading}
          isInvalid={personErr.error}
          errorMsg={personErr.msg}
        />
      </Row>

      <Row className="">
        <Form.Group>
          <Form.Label>Escopo</Form.Label>
          <div className="d-flex align-items-center gap-4">
            <Form.Check
              type="radio"
              name="role"
              id="role-admin"
              label="Administrador"
              value="admin"
              onChange={(e) => {
                if (e.target.checked) {
                  setRole("admin");
                }
              }}
              checked={role === "admin"}
              disabled={isUsersLoading}
            />
            <Form.Check
              type="radio"
              name="role"
              id="role-user"
              label="Usuário"
              value="user"
              onChange={(e) => {
                if (e.target.checked) {
                  setRole("user");
                }
              }}
              checked={type === "add" || role === "user"}
              disabled={isUsersLoading}
            />
          </div>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <InputComponent
          type="password"
          label="Senha"
          placeholder="***********"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            if (e.target.value.length >= 8)
              setPasswordErr({ error: false, msg: "" });
          }}
          inputId="password"
          isInvalid={passwordErr.error}
          errorMsg={passwordErr.msg}
          disabled={isUsersLoading}
        />
      </Row>
    </ModalContainer>
  );
};

export default UsersModal;
