// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-primary: #6537e1;
  --color-active: #402392;
  --color-white: white;
  --color-black: black;
  --color-gray: #424242;
  --color-gray-1: #707070;
  --color-gray-0: #ffffff4f;

  --color-success: #259e00;
  --color-danger: #dc3545;

  --btn-hover: #f4f4f4;
  --input-border-focus: #7a54e2af;
  --input-shadow: 0 0 0 0.25rem rgba(122, 84, 226, 0.25);

  --bg-color: #f4f4f4;
  --tr-color: #efefef;
}

* {
  font-family: "Segoe UI", sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  background-color: var(--bg-color);
}

.Toastify__toast-container {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.row {
  margin-inline: 0px;
}

::-webkit-scrollbar {
  width: 3px;
  /* display: none; */
}

::-webkit-scrollbar-track {
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background: #adb5bd;
}

::-webkit-scrollbar-button {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Global.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;;EAEzB,wBAAwB;EACxB,uBAAuB;;EAEvB,oBAAoB;EACpB,+BAA+B;EAC/B,sDAAsD;;EAEtD,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,iCAAiC;AACnC;;AAEA;EACE,kCAA6B;EAA7B,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[":root {\n  --color-primary: #6537e1;\n  --color-active: #402392;\n  --color-white: white;\n  --color-black: black;\n  --color-gray: #424242;\n  --color-gray-1: #707070;\n  --color-gray-0: #ffffff4f;\n\n  --color-success: #259e00;\n  --color-danger: #dc3545;\n\n  --btn-hover: #f4f4f4;\n  --input-border-focus: #7a54e2af;\n  --input-shadow: 0 0 0 0.25rem rgba(122, 84, 226, 0.25);\n\n  --bg-color: #f4f4f4;\n  --tr-color: #efefef;\n}\n\n* {\n  font-family: \"Segoe UI\", sans-serif;\n}\n\nbody {\n  height: 100vh;\n  margin: 0;\n  background-color: var(--bg-color);\n}\n\n.Toastify__toast-container {\n  width: fit-content !important;\n}\n\n.row {\n  margin-inline: 0px;\n}\n\n::-webkit-scrollbar {\n  width: 3px;\n  /* display: none; */\n}\n\n::-webkit-scrollbar-track {\n  border-radius: 2.5px;\n}\n\n::-webkit-scrollbar-thumb {\n  border-radius: 2.5px;\n  background: #adb5bd;\n}\n\n::-webkit-scrollbar-button {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
