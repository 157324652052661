// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle.btn.btn-primary {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.form-label.dropdown-label {
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Select/style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,mCAAmC;EACnC,2DAA2D;EAC3D,sCAAsC;AACxC;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".dropdown-toggle.btn.btn-primary {\n  color: var(--bs-body-color);\n  background-color: var(--bs-body-bg);\n  border: var(--bs-border-width) solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n}\n\n.form-label.dropdown-label {\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
