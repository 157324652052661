//components
import { Col, Row } from "react-bootstrap";

//style
import { LogoImg } from "./style";

import LogoWhite from "../../Assets/LogoBootclient-white.svg";
import Logo from "../../Assets/LogoBootclient.svg";
import LogoSmall from "../../Assets/flex-white.png";

type LogoProps = {
  type?: "white" | "small" | "default";
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  className?: string;
};

const BootclientLogo = ({
  type,
  width,
  maxWidth,
  height,
  maxHeight,
  className,
}: LogoProps) => {
  return (
    <LogoImg
      alt="bootclient logo"
      src={type === "white" ? LogoWhite : type === "small" ? LogoSmall : Logo}
      className={className ? className : ""}
      width={width}
      maxWidth={maxWidth}
      height={height}
      maxHeight={maxHeight}
    />
  );
};

export default BootclientLogo;
