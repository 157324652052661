// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  padding: 0.4rem 0.75rem;
  margin: 0px 5px;
}

.btn.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn.btn-primary:active,
.btn.btn-primary:hover {
  background-color: var(--color-active);
  border-color: var(--color-active);
}

.btn.btn-primary:focus-visible {
  box-shadow: var(--input-shadow);
}

.btn.btn-light {
  color: var(--color-active);
}

.btn.btn-light:active,
.btn.btn-light:hover {
  color: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Button/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,kCAAkC;AACpC;;AAEA;;EAEE,qCAAqC;EACrC,iCAAiC;AACnC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,2BAA2B;AAC7B","sourcesContent":[".btn {\n  padding: 0.4rem 0.75rem;\n  margin: 0px 5px;\n}\n\n.btn.btn-primary {\n  background-color: var(--color-primary);\n  border-color: var(--color-primary);\n}\n\n.btn.btn-primary:active,\n.btn.btn-primary:hover {\n  background-color: var(--color-active);\n  border-color: var(--color-active);\n}\n\n.btn.btn-primary:focus-visible {\n  box-shadow: var(--input-shadow);\n}\n\n.btn.btn-light {\n  color: var(--color-active);\n}\n\n.btn.btn-light:active,\n.btn.btn-light:hover {\n  color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
