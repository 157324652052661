import Swal from "sweetalert2";

type AlertProps = {
  title: string;
  text: string;
  icon: "warning" | "error" | "success" | "info" | "question";
  showCancelButton?: boolean;
  confirmButtonText: string;
  cancelButtonText?: string;
  allowOutsideClick?: boolean;
  callback: () => void;
};

export default class SweetAlert {
  static default({
    title,
    text,
    icon,
    showCancelButton = false,
    confirmButtonText,
    cancelButtonText = "Cancelar",
    allowOutsideClick = true,
    callback,
  }: AlertProps) {
    const swalButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary mr-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });

    swalButtons
      .fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: showCancelButton,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        allowOutsideClick: allowOutsideClick,
      })
      .then((result) => {
        if (result.isConfirmed) {
          callback();
        }
      });
  }
}
